// @mui material components
import Card from "@mui/material/Card";
// import Tooltip from "@mui/material/Tooltip";
// import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";
// import MDBadgeDot from "components/MDBadgeDot";
import PieChart from "examples/Charts/PieChart";

function Requests({ data }) {
  // const labels = data.map((request) => request.label);
  // const values = data.map((request) => request.value);
  const labels = [];
  const values = [];
  let totRequests = 0;
  data.forEach((item) => {
    if (item.label !== "Preventivatore prima richiesta") {
      labels.push(item.label);
      values.push(item.value);
      totRequests += item.value;
    }
  });
  const dataRequests = {
    labels,
    datasets: {
      label: "Projects",
      backgroundColors: [
        "info",
        "primary",
        "secondary",
        "dark",
        "light",
        "success",
        "warning",
        "light",
      ],
      data: values,
    },
  };

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6">Le richieste di contatto</MDTypography>
      </MDBox>
      <MDBox mt={3}>
        <Grid container alignItems="center">
          <Grid item xs={2} />
          <Grid item xs={8}>
            <PieChart chart={dataRequests} height="12.5rem" />
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </MDBox>
      <MDBox mt={3} px={2} mb={3}>
        Totale richieste <span style={{ fontWeight: "bold" }}>{totRequests}</span>, divise per:
        {labels.map((label, index) => (
          <div key={`label_sistema_operativo_${index.toString()}`}>
            <span style={{ fontSize: "14px" }}>{label}:</span>{" "}
            <span style={{ fontWeight: "bold" }}>{values[index]}</span>
          </div>
        ))}
      </MDBox>
    </Card>
  );
}

// Typechecking props for the Requests
Requests.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
};

export default Requests;
