// @mui material components
// import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import getFeed from "api/feed/getFeed";
import getFeedSaved from "api/feed/getFeedSaved";
// import addFeed from "api/feed/addFeed";
import editFeed from "api/feed/editFeed";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import FitScreen from "@mui/icons-material/FitScreen";
// import { useMaterialUIController } from "context";
// import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "layouts/backoffice/feed/pagination";

// Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import config from "config/config";
import moment from "moment";

const { imageRoot } = config;

function Feed() {
  // const [controller, dispatch] = useMaterialUIController();
  // eslint-disable-next-line
  const [feed, setFeed] = useState([]);
  // const [popup, setPopup] = useState(false);
  const [isRequestReady, setIsRequestReady] = useState(false);
  // eslint-disable-next-line
  const [pages, setPages] = useState(0);
  const [next, setNext] = useState("");
  const [topic, setTopic] = useState("");
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  // const [popupVerifyEmail, setPopupVerifyEmail] = useState(false);
  // const [popupVerifyEmailError, setPopupVerifyEmailError] = useState(false);

  function getFeedAlreadySaved() {
    // SET TOPIC FILTER
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const getTopic = urlParams.get("topic");
    if (getTopic) {
      setTopic(getTopic);
    }
    getFeedSaved(page, limit, getTopic || "")
      .then((resInner) => {
        if (resInner.totalPages > 0) {
          setPages(resInner.totalPages);
        }
        if (resInner.results && resInner.results.length > 0) {
          setFeed(resInner.results);
          setIsRequestReady(true);
        }
      })
      // eslint-disable-next-line
    .catch((err) => console.log("error", err));
  }

  useEffect(() => {
    getFeedAlreadySaved();
    // console.log("è cambiato il client", client);
  }, [page, limit]);

  useEffect(() => {
    setIsRequestReady(false);
    getFeedAlreadySaved();
    // console.log("è cambiato il client", client);
  }, []);

  function getFeedFromFacebook() {
    setIsRequestReady(false);
    getFeed()
      .then((res) => {
        if (res.code && res.code === 200) {
          // console.log("res.next", res.next);
          if (res.next) {
            setNext(res.next);
          } else {
            setNext("");
          }
          getFeedAlreadySaved();
        }
        // if (res.facebookFeed && res.facebookFeed.data && res.facebookFeed.data.length > 0) {
        //   res.facebookFeed.data.forEach((el, index) => {
        //     const elementToAdd = { ...el, facebokid: el.id };
        //     delete elementToAdd.id;
        //     addFeed(el)
        //       .then(() => {
        //         if (index === res.facebookFeed.data.length - 1) {
        //           setIsRequestReady(false);
        //           getFeedAlreadySaved();
        //         }
        //       })
        //       // eslint-disable-next-line
        //       .catch((err) => console.log("error", err));
        //   });
        // }
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error", err));
  }

  function getNextFeedFromFacebook() {
    setIsRequestReady(false);
    getFeed(next)
      .then((res) => {
        if (res.code && res.code === 200) {
          // console.log("res.next", res.next);
          if (res.next) {
            setNext(res.next);
          } else {
            setNext("");
          }
          getFeedAlreadySaved();
        }
        // if (res.facebookFeed && res.facebookFeed.data && res.facebookFeed.data.length > 0) {
        //   res.facebookFeed.data.forEach((el, index) => {
        //     const elementToAdd = { ...el, facebokid: el.id };
        //     delete elementToAdd.id;
        //     addFeed(el)
        //       .then(() => {
        //         if (index === res.facebookFeed.data.length - 1) {
        //           setIsRequestReady(false);
        //           getFeedAlreadySaved();
        //         }
        //       })
        //       // eslint-disable-next-line
        //       .catch((err) => console.log("error", err));
        //   });
        // }
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error", err));
  }

  function getDateFormatted(date) {
    return `${moment(date).format("DD")}/${moment(date).format("MM")}/${moment(date).format(
      "YYYY"
    )} - ${moment(date).format("HH")}:${moment(date).format("mm")}:${moment(date).format("ss")}`;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <IconButton
          onClick={() => {
            getFeedFromFacebook();
          }}
          size="small"
          disableRipple
        >
          Aggiorna <Icon fontSize="medium">replay</Icon>
        </IconButton>
        {next ? (
          <IconButton
            onClick={() => {
              getNextFeedFromFacebook();
            }}
            size="small"
            disableRipple
          >
            Next <Icon fontSize="medium">next</Icon>
          </IconButton>
        ) : null}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <div style={{ lineHeight: "40px", marginRight: "15px" }}>Elementi visualizzati:</div>
            <select
              onChange={(e) => {
                setLimit(e.target.value);
              }}
              value={limit}
              className="selectDefault"
              style={{
                padding: "0.75rem",
                borderRadius: "0.375rem",
                borderColor: "#d2d6da",
                marginBottom: "30px",
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <div>
            {topic}
            {topic ? (
              <IconButton
                onClick={() => {
                  window.location.href = "/feed";
                }}
                size="small"
                disableRipple
              >
                <Icon fontSize="medium">close</Icon>
              </IconButton>
            ) : null}
          </div>
        </div>
        <div>
          <div>
            <div className="table_users_list">
              <table style={{ border: "solid 1px #dedede", width: "100%" }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "40%",
                        textAlign: "center",
                      }}
                    >
                      Immagine
                    </th>
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "10%",
                        textAlign: "center",
                      }}
                    >
                      Data
                    </th>
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "10%",
                        textAlign: "center",
                      }}
                    >
                      Utente
                    </th>
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "30%",
                        textAlign: "center",
                      }}
                    >
                      Testo
                    </th>
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "20%",
                        textAlign: "center",
                      }}
                    >
                      Accetta/Home
                    </th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: "14px", textAlign: "center" }}>
                  {isRequestReady ? (
                    <>
                      {feed && feed.length > 0 ? (
                        <>
                          {feed.map((el, index) => (
                            <tr
                              style={{
                                borderBottom: "solid 1px #dedede",
                                backgroundColor: index % 2 === 0 ? "#fff" : "#eeeeee",
                              }}
                              key={`stealer-${index.toString()}`}
                            >
                              <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                                {el.full_picture && el.from && el.from.name ? (
                                  <img
                                    src={imageRoot + el.full_picture}
                                    alt={el.from.name}
                                    style={{ maxWidth: "100%", height: "auto" }}
                                  />
                                ) : null}
                              </td>
                              <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                                {el.created_time ? getDateFormatted(el.created_time) : null}
                              </td>
                              <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                                {el.from && el.from.name ? el.from.name : null}
                              </td>
                              <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                                {el.message ? el.message : null}
                              </td>
                              <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                                <IconButton
                                  onClick={() => {
                                    const feedToEdit = { ...el };
                                    if (el.accepted) {
                                      Object.assign(feedToEdit, { accepted: false });
                                    } else {
                                      Object.assign(feedToEdit, { accepted: true });
                                    }
                                    // eslint-disable-next-line
                                    editFeed(feedToEdit, el.id)
                                      .then(() => {
                                        getFeedAlreadySaved();
                                      })
                                      .catch((err) => {
                                        // eslint-disable-next-line
                                        console.log("error editing", err);
                                      });
                                  }}
                                  size="small"
                                  disableRipple
                                  style={el.accepted ? { color: "green" } : { color: "red" }}
                                >
                                  <Icon fontSize="medium">check</Icon>
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    const feedToEdit = { ...el };
                                    if (el.doubleSize) {
                                      Object.assign(feedToEdit, { doubleSize: false });
                                    } else {
                                      Object.assign(feedToEdit, { doubleSize: true });
                                    }
                                    // eslint-disable-next-line
                                    editFeed(feedToEdit, el.id)
                                      .then(() => {
                                        getFeedAlreadySaved();
                                      })
                                      .catch((err) => {
                                        // eslint-disable-next-line
                                        console.log("error editing", err);
                                      });
                                  }}
                                  size="small"
                                  disableRipple
                                  style={el.doubleSize ? { color: "green" } : { color: "red" }}
                                >
                                  <FitScreen fontSize="medium" />
                                </IconButton>
                                {!el.accepted && !el.inHome ? (
                                  <IconButton
                                    onClick={() => {
                                      const feedToEdit = { ...el };
                                      if (el.deleted) {
                                        Object.assign(feedToEdit, { deleted: false });
                                      } else {
                                        Object.assign(feedToEdit, { deleted: true });
                                      }
                                      // eslint-disable-next-line
                                      editFeed(feedToEdit, el.id)
                                        .then(() => {
                                          getFeedAlreadySaved();
                                        })
                                        .catch((err) => {
                                          // eslint-disable-next-line
                                        console.log("error editing", err);
                                        });
                                    }}
                                    size="small"
                                    disableRipple
                                    style={el.accepted ? { color: "green" } : { color: "red" }}
                                  >
                                    <Icon fontSize="medium">delete</Icon>
                                  </IconButton>
                                ) : null}
                                <IconButton
                                  onClick={() => {
                                    const feedToEdit = { ...el };
                                    if (el.inHome) {
                                      Object.assign(feedToEdit, { inHome: false });
                                    } else {
                                      Object.assign(feedToEdit, { inHome: true });
                                    }
                                    // eslint-disable-next-line
                                    editFeed(feedToEdit, el.id)
                                      .then(() => {
                                        getFeedAlreadySaved();
                                      })
                                      .catch((err) => {
                                        // eslint-disable-next-line
                                        console.log("error editing", err);
                                      });
                                  }}
                                  style={el.inHome ? { color: "green" } : { color: "red" }}
                                  size="small"
                                  disableRipple
                                >
                                  <Icon fontSize="medium">home</Icon>
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <tr>
                          <td />
                          <td />
                          <td style={{ width: "100%", display: "block", padding: "10px 0" }}>
                            Non ci sono ancora istanze di Stealers
                          </td>
                          <td />
                          <td />
                          <td />
                        </tr>
                      )}
                    </>
                  ) : (
                    <tr>
                      <td />
                      <td />
                      <td
                        style={{
                          width: "100%",
                          display: "block",
                          padding: "10px 0",
                          position: "relative",
                        }}
                      >
                        <div className="loader" />
                      </td>
                      <td />
                      <td />
                      <td />
                    </tr>
                  )}
                </tbody>
              </table>
              {pages && pages > 1 ? (
                <Pagination setPage={setPage} pages={pages} active={page} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Feed;
