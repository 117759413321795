// @mui material components
// import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
// import getTokens from "api/getTokens";
import getAllUser from "api/users/getAllUser";
import getUserFeed from "api/users/getUserFeed";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import MDInput from "components/MDInput";
// import { useMaterialUIController } from "context";
import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "layouts/backoffice/feed/pagination";
// import ReactTable from "react-table";

// Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function UserList() {
  // const [controller, dispatch] = useMaterialUIController();
  const [users, setUsers] = useState([]);
  const [originalUsers, setOriginalUsers] = useState([]);
  const [popup, setPopup] = useState(false);
  const [popupSuccess, setPopupSuccess] = useState(false);
  const [limit, setLimit] = useState(10);
  // eslint-disable-next-line
  const [pages, setPages] = useState(0);
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  // const [next, setNext] = useState("");
  const [orderBy, setOrderBy] = useState("email");

  useEffect(() => {
    getAllUser(page, limit)
      .then((res) => {
        if (res.totalPages > 0) {
          setPages(res.totalPages);
        }
        if (res.results && res.results.length > 0) {
          setOriginalUsers(res.results);
          setUsers(res.results.sort((a, b) => a.email.localeCompare(b.email)));
        }
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error", err));
  }, [page, limit]);

  useEffect(() => {
    getAllUser(page, limit)
      .then((res) => {
        if (res.totalPages > 0) {
          setPages(res.totalPages);
        }
        if (res.results && res.results.length > 0) {
          setOriginalUsers(res.results);
          setUsers(res.results.sort((a, b) => a.email.localeCompare(b.email)));
        }
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error", err));
  }, []);

  // function getInput(type, editFunction, defaultValue) {
  //   return (
  //     <MDInput
  //       type="input"
  //       label={type}
  //       fullWidth
  //       defaultValue={defaultValue}
  //       onChange={(e) => {
  //         editFunction(e.target.value);
  //       }}
  //       style={{ marginBottom: "30px" }}
  //     />
  //   );
  // }
  //
  // function getSelect(options, editFunction, defaultValue) {
  //   return (
  //     <select
  //       onChange={(e) => {
  //         editFunction(e.target.value);
  //       }}
  //       defaultValue={defaultValue}
  //       style={{
  //         width: "100%",
  //         padding: "0.75rem",
  //         borderRadius: "0.375rem",
  //         borderColor: "#d2d6da",
  //       }}
  //     >
  //       {options.map((el, index) => (
  //         <option value={el} key={`option-role-${index.toString()}`}>
  //           {el}
  //         </option>
  //       ))}
  //     </select>
  //   );
  // }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {popup ? (
        <SweetAlert
          style={{ display: "block" }}
          title="AGGIORNAMENTO"
          onConfirm={() => {
            // TODO chiamata modifica utente
          }}
          onCancel={() => {}}
          // confirmBtnCssClass={classes.button + " " + classes.success}
          confirmBtnStyle={{ display: "none" }}
        >
          Stiamo aggiornando il feed dell&apos;utente...
        </SweetAlert>
      ) : null}
      {popupSuccess ? (
        <SweetAlert
          style={{ display: "block" }}
          title="Feed aggiornato"
          success
          onConfirm={() => {
            setPopupSuccess(false);
          }}
          confirmBtnText="Ok"
          confirmBtnStyle={{ boxShadow: "none", color: "blue", marginLeft: "10px" }}
        >
          {/* eslint-disable-next-line */}
          Il feed dell'utente è stato aggiornato
        </SweetAlert>
      ) : null}
      {users && users.length > 0 ? (
        <div className="table_users_list">
          <div style={{ display: "flex" }}>
            <div style={{ lineHeight: "40px", marginRight: "15px" }}>Elementi visualizzati:</div>
            <select
              onChange={(e) => {
                setLimit(e.target.value);
              }}
              value={limit}
              className="selectDefault"
              style={{
                padding: "0.75rem",
                borderRadius: "0.375rem",
                borderColor: "#d2d6da",
                marginBottom: "30px",
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <table style={{ border: "solid 1px #dedede" }}>
            <thead>
              <tr>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const filterUsers = [].concat(users);
                    if (orderBy === "email") {
                      setUsers(filterUsers.sort((a, b) => b.email.localeCompare(a.email)));
                      setOrderBy("emailReverse");
                    } else {
                      setUsers(filterUsers.sort((a, b) => a.email.localeCompare(b.email)));
                      setOrderBy("email");
                    }
                  }}
                >
                  Email
                  {orderBy === "email" ? <Icon fontSize="medium">south</Icon> : null}
                  {orderBy === "emailReverse" ? <Icon fontSize="medium">north</Icon> : null}
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  Username
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  Fan page
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  Update
                </th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "14px", textAlign: "center" }}>
              <tr>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  <MDInput
                    type="input"
                    label="Email"
                    fullWidth
                    onChange={(e) => {
                      // e.target.value;
                      // console.log(e.target.value);
                      const filterUsers = [].concat(originalUsers);
                      // filterUsers.filter((el) => el.email.includes(e.target.value));
                      setUsers(filterUsers.filter((el) => el.email.includes(e.target.value)));
                      // console.log("users filteredddddd", filterUsers, users);
                    }}
                  />
                </th>
                <th
                  style={{
                    padding: "10px",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  /
                </th>
                <th
                  style={{
                    padding: "10px",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  /
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  /
                </th>
              </tr>
              {users.map((el, index) => (
                <tr style={{ borderBottom: "solid 1px #dedede" }} key={`user-${index.toString()}`}>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    {el.email}
                  </td>
                  {/* TODO select */}
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    {el.name}
                  </td>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    {el.fbAccounts && el.fbAccounts.length > 0
                      ? el.fbAccounts.map((fanpage) => (
                          <div key={`fanpage-name-${fanpage.id.toString()}`}>
                            <a
                              target="_blank"
                              href={`https://www.facebook.com/profile.php?id=${fanpage.id}`}
                              rel="noreferrer"
                            >
                              {fanpage.name} - {fanpage.id.toString()}
                            </a>
                          </div>
                        ))
                      : null}
                  </td>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    {el.fbAccounts && el.fbAccounts.length > 0
                      ? el.fbAccounts.map((fanpage) => (
                          <div key={`fanpage-actions-${fanpage.id.toString()}`}>
                            <IconButton
                              onClick={() => {
                                // eslint-disable-next-line
                                setPopup(true);
                                getUserFeed(el.id, el.fbId, el.fbAccounts)
                                  .then((res) => {
                                    // eslint-disable-next-line
                                    setPopup(false);
                                    setPopupSuccess(true);
                                    console.log("res", res);
                                  })
                                  // eslint-disable-next-line
                                  .catch((err) => console.log("error", err));
                              }}
                              size="small"
                              disableRipple
                            >
                              <Icon fontSize="medium">update</Icon>
                            </IconButton>
                          </div>
                        ))
                      : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {pages && pages > 1 ? <Pagination setPage={setPage} pages={pages} active={page} /> : null}
        </div>
      ) : null}
    </DashboardLayout>
  );
}

export default UserList;
