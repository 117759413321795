// @mui material components
// import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import getPost from "api/post/getPost";
import getFeed from "api/feed/getFeedHome";
// import addFeed from "api/feed/addFeed";
import editPost from "api/post/updatePost";
import addPost from "api/post/addPost";
// eslint-disable-next-line
import uploadImagePost from "api/post/uploadImagePost";
import deletePost from "api/post/deletePost";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
// import { useMaterialUIController } from "context";
import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "layouts/backoffice/feed/pagination";
import PopupAddingPost from "layouts/backoffice/post/popupAddingPost";
// import UploadImage from "layouts/backoffice/post/uploadImage";

// Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import config from "config/config";

const { imageRoot } = config;

function Post() {
  // const [controller, dispatch] = useMaterialUIController();
  // eslint-disable-next-line
  const [post, setPost] = useState([]);
  const [feed, setFeed] = useState([]);
  // const [popup, setPopup] = useState(false);
  const [popupSuccess, setPopupSuccess] = useState(false);
  const [popupTextSuccess, setPopupTextSuccess] = useState("");
  const [popupTitleSuccess, setPopupTitleSuccess] = useState("");
  const [isRequestReady, setIsRequestReady] = useState(false);
  const [isAddingPost, setIsAddingPost] = useState(false);
  const [isEditingPost, setIsEditingPost] = useState(false);
  const [editingElement, setEditingElement] = useState({});
  const [postTotalNumber, setPostTotalNumber] = useState(0);
  // eslint-disable-next-line
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);

  function getAllPosts() {
    getPost(page, limit)
      .then((resInner) => {
        if (resInner.posts && resInner.posts.totalResults && resInner.posts.totalResults > 0) {
          setPostTotalNumber(resInner.posts.totalResults);
        }
        if (resInner.posts && resInner.posts.results && resInner.posts.results.length > 0) {
          setPost(resInner.posts.results);
          setIsRequestReady(true);
        } else {
          setIsRequestReady(true);
        }
      })
      // eslint-disable-next-line
    .catch((err) => console.log("error", err));
  }

  useEffect(() => {
    getAllPosts();
    // console.log("è cambiato il client", client);
  }, [page, limit]);

  useEffect(() => {
    setIsRequestReady(false);
    getAllPosts();
    // console.log("è cambiato il client", client);
  }, []);

  useEffect(() => {
    getFeed().then((feedIn) => {
      if (feedIn.feedForHome && feedIn.feedForHome.length > 0) {
        setFeed(feedIn.feedForHome);
      }
    });
  }, []);

  function confirmAddPost(elementToAdd) {
    const dataToSend = {
      type: elementToAdd.type,
      link: elementToAdd.link,
      title: elementToAdd.title,
      columns: elementToAdd.columns,
      orientation: elementToAdd.orientation,
      orderPosition: elementToAdd.orderPosition,
    };
    if (elementToAdd.type && elementToAdd.type.includes("post")) {
      dataToSend.post = elementToAdd.post;
    }
    if (elementToAdd.type && elementToAdd.type.includes("immagine")) {
      dataToSend.image = elementToAdd.image;
    }
    addPost(dataToSend)
      .then(() => {
        setIsRequestReady(false);
        setIsAddingPost(false);
        setPopupSuccess(true);
        getAllPosts();
        setPopupTextSuccess("Hai aggiunto un post con successo");
        setPopupTitleSuccess("Ottimo");
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.log("error editing", err);
      });
  }

  function confirmEditPost(elementToEdit) {
    const dataToSend = {
      type: elementToEdit.type,
      link: elementToEdit.link,
      title: elementToEdit.title,
      columns: elementToEdit.columns,
      orientation: elementToEdit.orientation,
      orderPosition: elementToEdit.orderPosition,
    };
    if (elementToEdit.type && elementToEdit.type.includes("post")) {
      dataToSend.post = elementToEdit.post;
    }
    if (elementToEdit.type && elementToEdit.type.includes("immagine")) {
      dataToSend.image = elementToEdit.image;
    }
    editPost(dataToSend, editingElement.id)
      .then(() => {
        setIsRequestReady(false);
        setIsEditingPost(false);
        setEditingElement("");
        setPopupSuccess(true);
        getAllPosts();
        setPopupTextSuccess("Hai modificato il post con successo");
        setPopupTitleSuccess("Ottimo");
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.log("error editing", err);
      });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {popupSuccess ? (
        <SweetAlert
          style={{ display: "block" }}
          title={popupTitleSuccess}
          success
          onConfirm={() => {
            setPopupSuccess(false);
          }}
          confirmBtnText="Ok"
          confirmBtnStyle={{ boxShadow: "none", color: "blue", marginLeft: "10px" }}
        >
          {popupTextSuccess}
        </SweetAlert>
      ) : null}
      {isAddingPost ? (
        // eslint-disable-next-line
        <PopupAddingPost complete={confirmAddPost} closePopup={setIsAddingPost} feed={feed} />
      ) : null}
      {isEditingPost ? (
        // eslint-disable-next-line
        <PopupAddingPost complete={confirmEditPost} closePopup={setIsEditingPost} feed={feed} elementToAdd={editingElement} />
      ) : null}
      <div>
        <IconButton
          onClick={() => {
            getAllPosts();
          }}
          size="small"
          disableRipple
        >
          Aggiorna <Icon fontSize="medium">replay</Icon>
        </IconButton>
        <IconButton
          onClick={() => {
            setIsAddingPost(true);
          }}
          size="small"
          style={{ marginLeft: "30px" }}
          disableRipple
        >
          Aggiungi <Icon fontSize="medium">add</Icon>
        </IconButton>
        <div style={{ display: "flex", marginTop: "30px" }}>
          <div style={{ lineHeight: "40px", marginRight: "15px" }}>Elementi visualizzati:</div>
          <select
            onChange={(e) => {
              setLimit(e.target.value);
            }}
            value={limit}
            className="selectDefault"
            style={{
              padding: "0.75rem",
              borderRadius: "0.375rem",
              borderColor: "#d2d6da",
              marginBottom: "30px",
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        <div>
          <div>
            <div className="table_users_list">
              <table style={{ border: "solid 1px #dedede", width: "100%" }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "40%",
                        textAlign: "center",
                      }}
                    >
                      Immagine
                    </th>
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "20%",
                        textAlign: "center",
                      }}
                    >
                      Titolo
                    </th>
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "20%",
                        textAlign: "center",
                      }}
                    >
                      Tipologia
                    </th>
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "10%",
                        textAlign: "center",
                      }}
                    >
                      Ordering
                    </th>
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "10%",
                        textAlign: "center",
                      }}
                    >
                      Edit
                    </th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: "14px", textAlign: "center" }}>
                  {isRequestReady ? (
                    <>
                      {post && post.length > 0 ? (
                        <>
                          {post.map((el, index) => (
                            <tr
                              style={{
                                borderBottom: "solid 1px #dedede",
                                backgroundColor: index % 2 === 0 ? "#fff" : "#eeeeee",
                              }}
                              key={`stealer-${index.toString()}`}
                            >
                              <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                                {el.image || (el.post && el.post.full_picture) ? (
                                  <img
                                    src={
                                      imageRoot +
                                      // eslint-disable-next-line
                                      (el.image
                                        ? el.image
                                        : el.post && el.post.full_picture
                                        ? el.post.full_picture
                                        : "")
                                    }
                                    alt={el.title || ""}
                                    style={{ maxWidth: "100%", height: "auto" }}
                                  />
                                ) : null}
                              </td>
                              <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                                {el.title || ""}
                              </td>
                              <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                                {el.type || ""}
                              </td>
                              <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                                {el.orderPosition ? (
                                  <>
                                    {el.orderPosition > 1 ? (
                                      <IconButton
                                        onClick={() => {
                                          const postToEdit = { ...el };
                                          Object.assign(postToEdit, {
                                            orderPosition: el.orderPosition - 1,
                                            post:
                                              postToEdit.post && postToEdit.post.id
                                                ? postToEdit.post.id
                                                : null,
                                          });
                                          editPost(postToEdit, el.id)
                                            .then(() => {
                                              setIsRequestReady(false);
                                              getAllPosts();
                                            })
                                            .catch((err) => {
                                              // eslint-disable-next-line
                                              console.log("error editing", err);
                                            });
                                        }}
                                        size="small"
                                        disableRipple
                                      >
                                        <ArrowDropUp fontSize="medium" />
                                      </IconButton>
                                    ) : null}
                                    {el.orderPosition}
                                    {el.orderPosition < postTotalNumber ? (
                                      <IconButton
                                        onClick={() => {
                                          const postToEdit = { ...el };
                                          Object.assign(postToEdit, {
                                            orderPosition: el.orderPosition + 1,
                                            post:
                                              postToEdit.post && postToEdit.post.id
                                                ? postToEdit.post.id
                                                : null,
                                          });
                                          editPost(postToEdit, el.id)
                                            .then(() => {
                                              setIsRequestReady(false);
                                              getAllPosts();
                                            })
                                            .catch((err) => {
                                              // eslint-disable-next-line
                                            console.log("error editing", err);
                                            });
                                        }}
                                        size="small"
                                        disableRipple
                                      >
                                        <ArrowDropDown fontSize="medium" />
                                      </IconButton>
                                    ) : null}
                                  </>
                                ) : null}
                              </td>
                              <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                                <IconButton
                                  onClick={() => {
                                    const postToEdit = { ...el };
                                    if (el.published) {
                                      Object.assign(postToEdit, {
                                        published: false,
                                        post:
                                          postToEdit.post && postToEdit.post.id
                                            ? postToEdit.post.id
                                            : null,
                                      });
                                    } else {
                                      Object.assign(postToEdit, {
                                        published: true,
                                        post:
                                          postToEdit.post && postToEdit.post.id
                                            ? postToEdit.post.id
                                            : null,
                                      });
                                    }
                                    editPost(postToEdit, el.id)
                                      .then(() => {
                                        setIsRequestReady(false);
                                        getAllPosts();
                                      })
                                      .catch((err) => {
                                        // eslint-disable-next-line
                                        console.log("error editing", err);
                                      });
                                  }}
                                  size="small"
                                  disableRipple
                                >
                                  <Icon fontSize="medium">
                                    {el.published ? "unpublished" : "publish"}
                                  </Icon>
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    const postEdit = {
                                      ...el,
                                      post: el.post && el.post.id ? el.post.id : "",
                                    };
                                    setEditingElement(postEdit);
                                    setIsEditingPost(true);
                                  }}
                                  size="small"
                                  disableRipple
                                >
                                  <Icon fontSize="medium">edit</Icon>
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    // eslint-disable-next-line
                                    deletePost(el.id)
                                      .then(() => {
                                        setIsRequestReady(false);
                                        getAllPosts();
                                      })
                                      .catch((err) => {
                                        // eslint-disable-next-line
                                        console.log("error editing", err);
                                      });
                                  }}
                                  size="small"
                                  disableRipple
                                >
                                  <Icon fontSize="medium">delete</Icon>
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <tr>
                          <td />
                          <td style={{ width: "100%", display: "block", padding: "10px 0" }}>
                            Non ci sono ancora post
                          </td>
                          <td />
                          <td />
                        </tr>
                      )}
                    </>
                  ) : (
                    <tr>
                      <td />
                      <td
                        style={{
                          width: "100%",
                          display: "block",
                          padding: "10px 0",
                          position: "relative",
                        }}
                      >
                        <div className="loader" />
                      </td>
                      <td />
                      <td />
                    </tr>
                  )}
                </tbody>
              </table>
              {pages && pages > 1 ? (
                <Pagination setPage={setPage} pages={pages} active={page} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Post;
