// @mui material components
import Card from "@mui/material/Card";
// import Tooltip from "@mui/material/Tooltip";
// import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";
// import MDBadgeDot from "components/MDBadgeDot";
import PieChart from "examples/Charts/PieChart";

function Views({ views, viewsMobile }) {
  // const labels = data.map((os) => os.label);
  // const values = data.map((os) => os.value);
  const labels = [];
  const values = [];
  if (viewsMobile > 0) {
    labels.concat(["Desktop", "Mobile"]);
    values.concat([views - viewsMobile, viewsMobile]);
  } else {
    labels.push("Desktop");
    values.push(views);
  }
  const dataViews = {
    labels,
    datasets: {
      label: "Projects",
      backgroundColors: ["info", "primary"],
      data: values,
    },
  };

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6">Dispositivi</MDTypography>
      </MDBox>
      <MDBox mt={3}>
        <Grid container alignItems="center">
          <Grid item xs={2} />
          <Grid item xs={8}>
            <PieChart chart={dataViews} height="12.5rem" />
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </MDBox>
      <MDBox mt={3} px={2} mb={3}>
        Hai ricevuto visite da:
        <div>
          <span style={{ fontSize: "14px" }}>Desktop:</span>{" "}
          <span style={{ fontWeight: "bold" }}>{viewsMobile ? views - viewsMobile : views}</span>
        </div>
        {viewsMobile > 0 ? (
          <div>
            <span style={{ fontSize: "14px" }}>Mobile:</span>{" "}
            <span style={{ fontWeight: "bold" }}>{viewsMobile}</span>
          </div>
        ) : null}
      </MDBox>
    </Card>
  );
}

// Typechecking props for the Views
Views.propTypes = {
  views: PropTypes.number.isRequired,
  viewsMobile: PropTypes.number.isRequired,
};

export default Views;
