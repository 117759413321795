// @mui material components
// import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
// import getTokens from "api/getTokens";
import getTopTopic from "api/feed/getTopTopic";
// import { useMaterialUIController } from "context";
import Pagination from "layouts/backoffice/feed/pagination";
// import ReactTable from "react-table";

// Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function TopTopic() {
  // const [controller, dispatch] = useMaterialUIController();
  const [topics, setTopics] = useState([]);
  const [limit, setLimit] = useState(10);
  // eslint-disable-next-line
  const [pages, setPages] = useState(0);
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  useEffect(() => {
    getTopTopic(page, limit)
      .then((res) => {
        if (res && res.trendlist && res.trendlist.totalPages && res.trendlist.totalPages > 0) {
          setPages(res.trendlist.totalPages);
        }
        if (res && res.trendlist && res.trendlist.results && res.trendlist.results.length > 0) {
          setTopics(res.trendlist.results);
        }
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error", err));
  }, [page, limit]);

  useEffect(() => {
    getTopTopic(page, limit)
      .then((res) => {
        if (res && res.trendlist && res.trendlist.totalPages && res.trendlist.totalPages > 0) {
          setPages(res.trendlist.totalPages);
        }
        if (res && res.trendlist && res.trendlist.results && res.trendlist.results.length > 0) {
          setTopics(res.trendlist.results);
        }
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error", err));
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {topics && topics.length > 0 ? (
        <div className="table_users_list">
          <div style={{ display: "flex" }}>
            <div style={{ lineHeight: "40px", marginRight: "15px" }}>Elementi visualizzati:</div>
            <select
              onChange={(e) => {
                setLimit(e.target.value);
              }}
              value={limit}
              className="selectDefault"
              style={{
                padding: "0.75rem",
                borderRadius: "0.375rem",
                borderColor: "#d2d6da",
                marginBottom: "30px",
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <table style={{ border: "solid 1px #dedede", width: "100%" }}>
            <thead>
              <tr>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "40%",
                    textAlign: "center",
                  }}
                >
                  Topic
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "30%",
                    textAlign: "center",
                  }}
                >
                  Count
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "30%",
                    textAlign: "center",
                  }}
                >
                  Link
                </th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "14px", textAlign: "center" }}>
              {topics.map((el, index) => (
                <tr style={{ borderBottom: "solid 1px #dedede" }} key={`user-${index.toString()}`}>
                  {/* eslint-disable-next-line */}
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>{el._id}</td>
                  {/* TODO select */}
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>{el.tot}</td>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    <div>
                      <a
                        target="_blank"
                        // eslint-disable-next-line
                        href={`https://www.farinalovers.com/it/topic/${el._id.startsWith("#") ? el._id.split("#")[1] : el._id}`}
                        rel="noreferrer"
                      >
                        Pagina
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a
                        target="_blank"
                        // eslint-disable-next-line
                        href={`/feed?topic=${el._id.startsWith("#") ? el._id.split("#")[1] : el._id}`}
                        rel="noreferrer"
                      >
                        Edit
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {pages && pages > 1 ? <Pagination setPage={setPage} pages={pages} active={page} /> : null}
        </div>
      ) : null}
    </DashboardLayout>
  );
}

export default TopTopic;
