// @mui material components
// import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
// import getTokens from "api/getTokens";
import getAllUser from "api/users/getAllUser";
import verifyEmail from "api/auth/verifyEmail";
import editUser from "api/users/editUser";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import MDInput from "components/MDInput";
// import { useMaterialUIController } from "context";
import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "layouts/backoffice/feed/pagination";
// import ReactTable from "react-table";

// Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function UserList() {
  // const [controller, dispatch] = useMaterialUIController();
  const [users, setUsers] = useState([]);
  const [originalUsers, setOriginalUsers] = useState([]);
  const [editElement, setEditElement] = useState(null);
  const [popup, setPopup] = useState(false);
  const [popupSuccess, setPopupSuccess] = useState(false);
  const [popupVerifyEmail, setPopupVerifyEmail] = useState(false);
  const [popupVerifyEmailError, setPopupVerifyEmailError] = useState(false);
  const [limit, setLimit] = useState(10);
  // eslint-disable-next-line
  const [pages, setPages] = useState(0);
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [orderBy, setOrderBy] = useState("email");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  useEffect(() => {
    getAllUser(page, limit)
      .then((res) => {
        if (res.totalPages > 0) {
          setPages(res.totalPages);
        }
        if (res.results && res.results.length > 0) {
          setOriginalUsers(res.results);
          setUsers(res.results.sort((a, b) => a.email.localeCompare(b.email)));
        }
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error", err));
  }, [page, limit]);

  useEffect(() => {
    getAllUser(page, limit)
      .then((res) => {
        if (res.totalPages > 0) {
          setPages(res.totalPages);
        }
        if (res.results && res.results.length > 0) {
          setOriginalUsers(res.results);
          setUsers(res.results.sort((a, b) => a.email.localeCompare(b.email)));
        }
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error", err));
  }, []);

  function getInput(type, editFunction, defaultValue) {
    return (
      <MDInput
        type="input"
        label={type}
        fullWidth
        defaultValue={defaultValue}
        onChange={(e) => {
          editFunction(e.target.value);
        }}
        style={{ marginBottom: "30px" }}
      />
    );
  }

  function getSelect(options, editFunction, defaultValue) {
    return (
      <select
        onChange={(e) => {
          editFunction(e.target.value);
        }}
        defaultValue={defaultValue}
        style={{
          width: "100%",
          padding: "0.75rem",
          borderRadius: "0.375rem",
          borderColor: "#d2d6da",
        }}
      >
        {options.map((el, index) => (
          <option value={el} key={`option-role-${index.toString()}`}>
            {el}
          </option>
        ))}
      </select>
    );
  }

  function sendVerificationEmail(user) {
    verifyEmail(user)
      .then((res) => {
        // eslint-disable-next-line
        console.log("res verify email", res);
        setPopupVerifyEmail(true);
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.log("error", err);
        setPopupVerifyEmailError(true);
      });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {popup ? (
        <SweetAlert
          style={{ display: "block" }}
          title={editElement.name}
          onConfirm={() => {
            // TODO chiamata modifica utente
            const userToEdit = {
              name,
              email,
              role,
            };
            if (password) {
              Object.assign(userToEdit, { password });
            }
            editUser(userToEdit, editElement.id)
              .then((res) => {
                // eslint-disable-next-line
                console.log("resssss", res);
                setPopupSuccess(true);
              })
              // eslint-disable-next-line
              .catch((err) => console.log("error", err));
            setPopup(false);
          }}
          onCancel={() => {
            setPopup(false);
            setEditElement(null);
            setName("");
            setEmail("");
            setRole("");
          }}
          confirmBtnText="Salva"
          cancelBtnText="Chiudi"
          showCancel
          // confirmBtnCssClass={classes.button + " " + classes.success}
          confirmBtnStyle={{ boxShadow: "none", color: "blue", marginLeft: "10px" }}
          cancelBtnStyle={{ color: "red" }}
        >
          {getInput("name", setName, name)}
          {getInput("email", setEmail, email)}
          {getInput("password", setPassword, password)}
          {getSelect(["user", "admin"], setRole, role)}
        </SweetAlert>
      ) : null}
      {popupSuccess ? (
        <SweetAlert
          style={{ display: "block" }}
          title="Utente modificato"
          success
          onConfirm={() => {
            setPopupSuccess(false);
          }}
          confirmBtnText="Ok"
          confirmBtnStyle={{ boxShadow: "none", color: "blue", marginLeft: "10px" }}
        >
          {/* eslint-disable-next-line */}
          L'utente è stato modificato con successo
        </SweetAlert>
      ) : null}
      {popupVerifyEmail ? (
        <SweetAlert
          style={{ display: "block" }}
          title="Email inviata"
          success
          onConfirm={() => {
            setPopupVerifyEmail(false);
          }}
          onCancel={() => {
            setPopupVerifyEmail(false);
          }}
          closeOnClickOutise
          confirmBtnText="Ok"
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          {/* eslint-disable-next-line */}
          {/* eslint-disable-next-line */}
          È stata inviata la mail di verifica all'utente, può
          confermare il suo indirizzo.
        </SweetAlert>
      ) : null}
      {popupVerifyEmailError ? (
        <SweetAlert
          style={{ display: "block" }}
          title="Errore verifica email"
          success
          onConfirm={() => {
            setPopupVerifyEmailError(false);
          }}
          onCancel={() => {
            setPopupVerifyEmailError(false);
          }}
          closeOnClickOutise
          confirmBtnText="Ok"
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          {/* eslint-disable-next-line */}
          C'è stato un errore nell'invio della mail di verifica all'utente, sei pregato di riprovare più tardi.
        </SweetAlert>
      ) : null}
      {users && users.length > 0 ? (
        <div className="table_users_list">
          <div style={{ display: "flex" }}>
            <div style={{ lineHeight: "40px", marginRight: "15px" }}>Elementi visualizzati:</div>
            <select
              onChange={(e) => {
                setLimit(e.target.value);
              }}
              value={limit}
              className="selectDefault"
              style={{
                padding: "0.75rem",
                borderRadius: "0.375rem",
                borderColor: "#d2d6da",
                marginBottom: "30px",
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <table style={{ border: "solid 1px #dedede" }}>
            <thead>
              <tr>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const filterUsers = [].concat(users);
                    if (orderBy === "email") {
                      setUsers(filterUsers.sort((a, b) => b.email.localeCompare(a.email)));
                      setOrderBy("emailReverse");
                    } else {
                      setUsers(filterUsers.sort((a, b) => a.email.localeCompare(b.email)));
                      setOrderBy("email");
                    }
                  }}
                >
                  Email
                  {orderBy === "email" ? <Icon fontSize="medium">south</Icon> : null}
                  {orderBy === "emailReverse" ? <Icon fontSize="medium">north</Icon> : null}
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const filterUsers = [].concat(users);
                    if (orderBy === "role") {
                      setUsers(filterUsers.sort((a, b) => b.role.localeCompare(a.role)));
                      setOrderBy("roleReverse");
                    } else {
                      setUsers(filterUsers.sort((a, b) => a.role.localeCompare(b.role)));
                      setOrderBy("role");
                    }
                  }}
                >
                  Ruolo
                  {orderBy === "role" ? <Icon fontSize="medium">south</Icon> : null}
                  {orderBy === "roleReverse" ? <Icon fontSize="medium">north</Icon> : null}
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const filterUsers = [].concat(users);
                    if (orderBy === "verifiedEmail") {
                      setUsers(filterUsers.sort((a, b) => a.isEmailVerified - b.isEmailVerified));
                      setOrderBy("verifiedEmailReverse");
                    } else {
                      setUsers(filterUsers.sort((a, b) => b.isEmailVerified - a.isEmailVerified));
                      setOrderBy("verifiedEmail");
                    }
                  }}
                >
                  Email verificata
                  {orderBy === "verifiedEmail" ? <Icon fontSize="medium">south</Icon> : null}
                  {orderBy === "verifiedEmailReverse" ? <Icon fontSize="medium">north</Icon> : null}
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const filterUsers = [].concat(users);
                    if (orderBy === "mfaEnabled") {
                      setUsers(filterUsers.sort((a, b) => a.mfaEnabled - b.mfaEnabled));
                      setOrderBy("mfaEnabledReverse");
                    } else {
                      setUsers(filterUsers.sort((a, b) => b.mfaEnabled - a.mfaEnabled));
                      setOrderBy("mfaEnabled");
                    }
                  }}
                >
                  Due fattori
                  {orderBy === "mfaEnabled" ? <Icon fontSize="medium">south</Icon> : null}
                  {orderBy === "mfaEnabledReverse" ? <Icon fontSize="medium">north</Icon> : null}
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  Modifica
                </th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "14px", textAlign: "center" }}>
              <tr>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  <MDInput
                    type="input"
                    label="Email"
                    fullWidth
                    onChange={(e) => {
                      // e.target.value;
                      // console.log(e.target.value);
                      const filterUsers = [].concat(originalUsers);
                      // filterUsers.filter((el) => el.email.includes(e.target.value));
                      setUsers(filterUsers.filter((el) => el.email.includes(e.target.value)));
                      // console.log("users filteredddddd", filterUsers, users);
                    }}
                  />
                </th>
                <th
                  style={{
                    padding: "10px",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  <MDInput
                    type="input"
                    label="Ruolo"
                    fullWidth
                    onChange={(e) => {
                      // e.target.value;
                      const filterUsers = [].concat(originalUsers);
                      // filterUsers.filter((el) => el.role.includes(e.target.value));
                      setUsers(filterUsers.filter((el) => el.role.includes(e.target.value)));
                    }}
                  />
                </th>
                <th
                  style={{
                    padding: "10px",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  /
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  /
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  /
                </th>
              </tr>
              {users.map((el, index) => (
                <tr style={{ borderBottom: "solid 1px #dedede" }} key={`user-${index.toString()}`}>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    {el.email}
                  </td>
                  {/* TODO select */}
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    {el.role}
                  </td>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    {el.isEmailVerified ? (
                      <Icon fontSize="medium">check</Icon>
                    ) : (
                      <div>
                        <Icon fontSize="medium">close</Icon>
                        <Icon
                          onClick={() => {
                            sendVerificationEmail(el);
                          }}
                          style={{ cursor: "pointer", marginLeft: "15px" }}
                          fontSize="medium"
                        >
                          email
                        </Icon>
                      </div>
                    )}
                  </td>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    {el.mfaEnabled ? (
                      <Icon fontSize="medium">check</Icon>
                    ) : (
                      <Icon fontSize="medium">close</Icon>
                    )}
                  </td>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    <IconButton
                      onClick={() => {
                        setPopup(true);
                        setEditElement(el);
                        setName(el.name);
                        setEmail(el.email);
                        setRole(el.role);
                      }}
                      size="small"
                      disableRipple
                    >
                      <Icon fontSize="medium">edit</Icon>
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {pages && pages > 1 ? <Pagination setPage={setPage} pages={pages} active={page} /> : null}
        </div>
      ) : null}
    </DashboardLayout>
  );
}

export default UserList;
