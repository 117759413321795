// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

function getPages(pages, active, setPage) {
  const baseArray = Array(pages)
    .fill()
    .map((_, i) => i + 1);
  return baseArray.map((el, index) => {
    if (
      el === 1 ||
      el === pages ||
      el === active ||
      el === active - 1 ||
      el === active + 1 ||
      el === active - 2 ||
      el === active + 2
    ) {
      // eslint-disable-next-line
      return (<div
          key={`pagination-page-${index.toString()}`}
          className={active === el ? "pagination active" : "pagination"}
          onClick={() => {
            if (active !== el) {
              setPage(el);
            }
          }}
        >
          {el}
        </div>
      );
    }
    if (el === active - 3 || el === active + 3) {
      return (
        <div className="pagination-separator" key={`pagination-page-${index.toString()}`}>
          ...
        </div>
      );
    }
    return null;
  });
}

function Pagination({ setPage, pages, active }) {
  return (
    <div className="pagination-container">
      <div className="pagination-inner">{getPages(pages, active, setPage)}</div>
    </div>
  );
}

// Typechecking props for the Pagination
Pagination.propTypes = {
  setPage: PropTypes.func.isRequired,
  pages: PropTypes.number.isRequired,
  active: PropTypes.number,
};

Pagination.defaultProps = {
  active: 0,
};

export default Pagination;
