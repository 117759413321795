const configuration = {
  development: {
    baseUrl: "http://localhost:3008",
    clientUrl: "https://localhost:3000",
    imageRoot: "http://localhost:3008/",
  },
  staging: {
    baseUrl: "https://www.farinalovers.com/api",
    clientUrl: "http://18.159.130.75",
    imageRoot: "https://www.farinalovers.com/api/",
  },
  production: {
    baseUrl: "https://www.farinalovers.com/api",
    clientUrl: "http://18.159.130.75",
    imageRoot: "https://www.farinalovers.com/api/",
  },
};

export default configuration;
