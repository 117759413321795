// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import MDInput from "components/MDInput";
import MDDropzone from "components/MDDropzone";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import config from "config/config";
import uploadImagePost from "api/post/uploadImagePost";

const { imageRoot, baseUrl } = config;

function getInput(type, editFunction, defaultValue) {
  return (
    <MDInput
      type={type === "ordering" ? "number" : "input"}
      label={type}
      fullWidth
      defaultValue={defaultValue}
      onChange={(e) => {
        editFunction(e.target.value);
      }}
      style={{ marginBottom: "30px" }}
    />
  );
}

const typeOptions = [
  { label: "Post", value: "post" },
  { label: "Immagine", value: "immagine" },
  { label: "Utente", value: "utente" },
  { label: "Post + immagine", value: "post+immagine" },
  { label: "Post + utente", value: "post+utente" },
];
const columnOptions = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];
const orientationOptions = [
  { label: "Left to right", value: "ltr" },
  { label: "Right to left", value: "rtl" },
];

function getSelect(type, options, editFunction, defaultValue, label) {
  return (
    <div>
      <div style={{ fontSize: "16px", marginBottom: "10px" }}>{label}</div>
      <select
        onChange={(e) => {
          editFunction(e.target.value);
        }}
        value={defaultValue}
        className="selectDefault"
        style={{
          padding: "0.75rem",
          borderRadius: "0.375rem",
          borderColor: "#d2d6da",
          marginBottom: "30px",
        }}
      >
        {options.map((el, index) => (
          <option key={`option-${type}-${index.toString()}`} value={el.value}>
            {el.label}
          </option>
        ))}
      </select>
    </div>
  );
}

function PopupAddingPost({
  complete,
  closePopup,
  feed,
  elementToAdd = {},
  popupTitle = "Crea post",
}) {
  const [typeAdding, setTypeAdding] = useState(elementToAdd.type || "post");
  const [orderingAdding, setOrderingAdding] = useState(elementToAdd.orderPosition || 1);
  const [imageAdding, setImageAdding] = useState(elementToAdd.image || "");
  const [postToAssociateAdding, setPostToAssociateAdding] = useState(elementToAdd.post || "");
  const [columnAdding, setColumnAdding] = useState(elementToAdd.columns || "1");
  const [orientationAdding, setOrientationAdding] = useState(elementToAdd.orientation || "ltr");
  const [linkAdding, setLinkAdding] = useState(elementToAdd.link || "");
  const [titleAdding, setTitleAdding] = useState(elementToAdd.title || "");

  function imageUploaded(res) {
    if (res && res.file) {
      setImageAdding(`public/images/${res.file}`);
    }
  }

  let postAssociated = null;
  if (
    postToAssociateAdding &&
    feed &&
    feed.length > 0 &&
    feed.some((fe) => fe.id === postToAssociateAdding)
  ) {
    postAssociated = feed.find((fe) => fe.id === postToAssociateAdding);
  }

  return (
    <SweetAlert
      style={{ display: "block", maxHeight: "600px", overflowY: "auto" }}
      title={popupTitle}
      onConfirm={() => {
        const elementToSend = {
          type: typeAdding,
          orderPosition: orderingAdding,
          image: imageAdding,
          post: postToAssociateAdding,
          columns: columnAdding,
          orientation: orientationAdding,
          link: linkAdding,
          title: titleAdding,
        };
        complete(elementToSend);
      }}
      onCancel={() => {
        closePopup(false);
      }}
      showCancel
      confirmBtnCssClass="popupCloseButton"
      confirmBtnStyle={{
        boxShadow: "none",
      }}
      cancelBtnCssClass="popupCloseButton"
      cancelBtnStyle={{
        boxShadow: "none",
      }}
      confirmBtnText="Salva"
      cancelBtnText="Annulla"
    >
      {/* eslint-disable-next-line */}
      <div>
        {getSelect("typeAdding", typeOptions, setTypeAdding, typeAdding, "Tipologia post")}
        {getInput("title", setTitleAdding, titleAdding)}
        {getInput("ordering", setOrderingAdding, orderingAdding)}
        {getSelect("columnAdding", columnOptions, setColumnAdding, columnAdding, "Numero colonne")}
        {typeAdding.includes("+")
          ? getSelect(
              "orientationAdding",
              orientationOptions,
              setOrientationAdding,
              orientationAdding,
              "Orientamento"
            )
          : null}
        {getInput("link", setLinkAdding, linkAdding)}
        {typeAdding.includes("immagine") ? (
          <div>
            {imageAdding ? (
              <div style={{ position: "relative" }}>
                <IconButton
                  style={{ position: "absolute", right: "0", top: "0" }}
                  onClick={() => {
                    setImageAdding("");
                  }}
                  size="small"
                  disableRipple
                >
                  <Icon fontSize="medium">close</Icon>
                </IconButton>
                <div style={{ fontSize: "16px" }}>Immagine:</div>
                <img
                  src={
                    imageRoot +
                    // eslint-disable-next-line
                    imageAdding
                  }
                  alt={titleAdding}
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
            ) : (
              <MDDropzone
                options={{
                  addRemoveLinks: true,
                  maxFilesize: 1000000,
                  // eslint-disable-next-line
                  url: (file) => {
                    return `${baseUrl}/v1/ping/pong`;
                  },
                  // eslint-disable-next-line
                  // complete: (file) => {
                  //   console.log("complete", file);
                  //   // eslint-disable-next-line
                  //   if (file._removeLink) {
                  //     // eslint-disable-next-line
                  //     file._removeLink.innerHTML = this.options.dictRemoveFile;
                  //   }
                  //   if (file.previewElement) {
                  //     return file.previewElement.classList.add("dz-complete");
                  //   }
                  // },
                  // eslint-disable-next-line
                  success: (file) => {
                    if (file && file.dataURL) {
                      // TODO gestire il multi caricamento contemporaneo o toglierlo
                      uploadImagePost(file).then((res) => {
                        imageUploaded(res);
                      });
                    }
                    return true;
                  },
                  // removedfile: (file) => {
                  //   console.log("ho cancellato", file);
                  //   if (file.previewElement != null && file.previewElement.parentNode != null) {
                  //     file.previewElement.parentNode.removeChild(file.previewElement);
                  //   }
                  //   // eslint-disable-next-line
                  //   return this._updateMaxFilesReachedClass();
                  // },
                }}
              />
            )}
          </div>
        ) : null}
        {typeAdding.includes("post") ? (
          <div>
            {postToAssociateAdding && postAssociated ? (
              // post associato selezionato, far vedere
              <div style={{ position: "relative" }}>
                <IconButton
                  style={{ position: "absolute", right: "0", top: "0" }}
                  onClick={() => {
                    setPostToAssociateAdding("");
                  }}
                  size="small"
                  disableRipple
                >
                  <Icon fontSize="medium">close</Icon>
                </IconButton>
                <div style={{ fontSize: "18px", marginBottom: "20px" }}>Post associato:</div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "0 auto 20px",
                    padding: "0 20px 0 0",
                  }}
                >
                  {postAssociated.full_picture &&
                  postAssociated.from &&
                  postAssociated.from.name ? (
                    <img
                      src={imageRoot + postAssociated.full_picture}
                      alt={postAssociated.from.name}
                      style={{ maxWidth: "200px", height: "auto" }}
                    />
                  ) : null}
                  <span style={{ fontSize: "14px", marginLeft: "20px" }}>
                    {postAssociated.from && postAssociated.from.name
                      ? postAssociated.from.name
                      : ""}
                  </span>
                </div>
              </div>
            ) : (
              // far scegliere il post da associare
              <div>
                <div style={{ fontSize: "18px", marginBottom: "20px" }}>
                  Scegli il post da associare:
                </div>
                <div>
                  {feed.map((fe, index) => (
                    <button
                      className="emply-label-button"
                      type="button"
                      style={{
                        border: "solid 1px #dedede",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "0 auto 20px",
                        padding: "0 20px 0 0",
                      }}
                      key={`post-to-associate-${fe.id.toString()}-${index.toString()}`}
                      onClick={() => {
                        setPostToAssociateAdding(fe.id);
                      }}
                    >
                      {fe.full_picture && fe.from && fe.from.name ? (
                        <img
                          src={imageRoot + fe.full_picture}
                          alt={fe.from.name}
                          style={{ maxWidth: "200px", height: "auto" }}
                        />
                      ) : null}
                      <span style={{ fontSize: "14px", marginLeft: "20px" }}>
                        {fe.from && fe.from.name ? fe.from.name : ""}
                      </span>
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </SweetAlert>
  );
}

// Typechecking props for the PopupAddingPost
PopupAddingPost.propTypes = {
  closePopup: PropTypes.func.isRequired,
  complete: PropTypes.func.isRequired,
  feed: PropTypes.arrayOf(PropTypes.object).isRequired,
  elementToAdd: PropTypes.objectOf(PropTypes.any),
  popupTitle: PropTypes.string,
};

PopupAddingPost.defaultProps = {
  elementToAdd: {},
  popupTitle: "Crea post",
};

export default PopupAddingPost;
