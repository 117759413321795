/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
// import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import SimpleCard from "examples/Cards/StatisticsCards/SimpleCard";
import OS from "layouts/dashboards/analytics/components/OS";
import Views from "layouts/dashboards/analytics/components/Views";
import Requests from "layouts/dashboards/analytics/components/Requests";
import { useMaterialUIController } from "context";

// Data
// import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
// import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

function Analytics() {
  // const { sales, tasks } = reportsLineChartData;
  // eslint-disable-next-line
  const [controller] = useMaterialUIController();
  // eslint-disable-next-line
  const { allStatistics } = controller;

  // console.log("allStatistics", allStatistics);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={2}>
        <MDBox mt={6} mb={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              {allStatistics &&
              allStatistics.views_per_os &&
              allStatistics.views_per_os.length > 0 ? (
                <MDBox mb={3}>
                  <OS data={allStatistics.views_per_os} />
                </MDBox>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {allStatistics && allStatistics.views_totali && allStatistics.views_totali > 0 ? (
                <MDBox mb={3}>
                  <Views
                    views={allStatistics.views_totali}
                    viewsMobile={allStatistics.views_mobile || 0}
                  />
                </MDBox>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {allStatistics && allStatistics.l_type && allStatistics.l_type.length > 0 ? (
                <MDBox mb={3}>
                  <Requests data={allStatistics.l_type} />
                </MDBox>
              ) : null}
            </Grid>
            {/* <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid> */}
            {/* <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid> */}
            {/* <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid> */}
          </Grid>
        </MDBox>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            {/* {allStatistics && allStatistics.views_totali ? (
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="leaderboard"
                    title="Visite totali"
                    count={allStatistics.views_totali}
                    percentage={{
                      color: "success",
                      amount: allStatistics.views_mobile || "0",
                      label: "di cui mobile",
                    }}
                  />
                </MDBox>
              </Grid>
            ) : null} */}
            {allStatistics &&
            allStatistics.views_per_route &&
            allStatistics.views_per_route.length > 0 ? (
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <SimpleCard color="dark" icon="weekend" title="Indirizzi navigati">
                    <div>
                      {allStatistics.views_per_route.map((route, index) => (
                        <div key={`view_per_route_${index.toString()}`}>
                          <span style={{ fontSize: "14px" }}>{route.label}:</span>{" "}
                          <span style={{ fontWeight: "bold" }}>{route.value}</span>
                        </div>
                      ))}
                    </div>
                  </SimpleCard>
                </MDBox>
              </Grid>
            ) : null}
            {allStatistics &&
            allStatistics.views_per_country &&
            allStatistics.views_per_country.length > 0 ? (
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <SimpleCard color="success" icon="store" title="Visite per paese">
                    <div>
                      {allStatistics.views_per_country.map((country, index) => (
                        <div key={`view_per_country_${index.toString()}`}>
                          <span style={{ fontSize: "14px" }}>{country.label}:</span>{" "}
                          <span style={{ fontWeight: "bold" }}>{country.value}</span>
                        </div>
                      ))}
                    </div>
                  </SimpleCard>
                </MDBox>
              </Grid>
            ) : null}
            {allStatistics &&
            allStatistics.views_per_language &&
            allStatistics.views_per_language.length > 0 ? (
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <SimpleCard color="primary" icon="flag" title="Lingua visite">
                    <div>
                      {allStatistics.views_per_language.map((language, index) => (
                        <div key={`view_per_language_${index.toString()}`}>
                          <span style={{ fontSize: "14px" }}>{language.label}:</span>{" "}
                          <span style={{ fontWeight: "bold" }}>{language.value}</span>
                        </div>
                      ))}
                    </div>
                  </SimpleCard>
                </MDBox>
              </Grid>
            ) : null}
          </Grid>
        </MDBox>
        {/* <MDBox mt={1.5}>
          <Grid container spacing={3}>
            {allStatistics &&
            allStatistics.views_per_os &&
            allStatistics.views_per_os.length > 0 ? (
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <SimpleCard color="error" icon="computer" title="View per os">
                    <div>
                      {allStatistics.views_per_os.map((os, index) => (
                        <div key={`view_per_os_${index.toString()}`}>
                          <span style={{ fontSize: "14px" }}>{os.label}:</span>{" "}
                          <span style={{ fontWeight: "bold" }}>{os.value}</span>
                        </div>
                      ))}
                    </div>
                  </SimpleCard>
                </MDBox>
              </Grid>
            ) : null}
          </Grid>
        </MDBox> */}
      </MDBox>
    </DashboardLayout>
  );
}

export default Analytics;
