/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import login from "api/auth/login";

function Basic() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const performLogin = () => {
    // console.log("email ", email);
    // console.log("password ", password);
    const dataToSend = {
      email,
      password,
    };
    return login(dataToSend)
      .then((resp) => {
        // eslint-disable-next-line
        console.log("login success", resp);
        setError(false);
        window.location.href = "/";
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.log("error login", err);
        setError(true);
      });
  };

  // console.log("error?", error);

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  // TODO gestire errori

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    performLogin();
                  }
                }}
                onChange={(e) => {
                  setEmail(e.target.value);
                  // console.log("targetttttt", e.target.value);
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    performLogin();
                  }
                }}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => {
                  performLogin();
                }}
              >
                sign in
              </MDButton>
            </MDBox>
            {error ? (
              <MDBox mt={4} mb={1}>
                Ops, qualcosa è andato storto! Controlla i dati immessi o riprova fra qualche minuto
              </MDBox>
            ) : null}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
